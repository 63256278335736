'use client'

import { useBookSearch, useBookSearchRefinements, useBookSearchSortBy } from '@hermes/search'
import {
  ArrowBackIcon,
  Button,
  CloseCircleIcon,
  SearchAltIcon,
  SearchIcon,
  useClickOutside,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useDebounceAction } from '@hooks/useDebounceAction'
import { useLocale } from '@hooks/useLocale'
import { useNavigation } from '@hooks/useNavigation'
import { useStage } from '@hooks/useStage'
import useTranslateMessage from '@hooks/useTranslateMessage'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import { setRecentSearch } from '@lib/utils/localStorageHelpers'
import { usePathname } from 'next/navigation'
import React, { useEffect, useRef, useState } from 'react'
import RecentSearch from './Recent'
import { SearchAreaContainer, StyledSearchArea } from './Search.styles'

export const SearchField = () => {
  const MIN_CHARS_VALID_SEARCH = 2
  const theme = useTheme()
  const navigation = useNavigation()
  const locale = useLocale()
  const stage = useStage()
  const region = getRegionFromLocale(locale)
  const { t } = useTranslateMessage()

  const { query, setQuery, semanticEnabled, resetQuery } = useBookSearch()
  const { resetRefinements } = useBookSearchRefinements()
  const { resetSorting } = useBookSearchSortBy()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const pathname = usePathname()
  const [recentSearchView, setRecentSearchView] = useState(false)
  const resultsContainerRef = useRef<HTMLDivElement>(null)
  const hideSearch = () => setRecentSearchView(false)
  const onSearchPage = pathname?.includes('/search')

  useClickOutside(resultsContainerRef, hideSearch)

  const handleClearSearch = () => {
    resetQuery()
    resetSorting()
    resetRefinements()
  }

  const navigateToSearchPage = () => {
    hideSearch()
    !onSearchPage && navigation.navigate.searchPage(query)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    hideSearch()
    if (event.code === 'Enter') {
      return navigateToSearchPage()
    }
  }

  const setRecentSearchDebounced = useDebounceAction((newValue: string) => {
    setRecentSearch(newValue, region, stage)
  }, 3000)

  const handleNavigateToSearchPage = (newValue: string) => {
    if (!onSearchPage && newValue.length > MIN_CHARS_VALID_SEARCH) {
      navigation.navigate.searchPage(newValue)
      setQuery(newValue)
    }
  }

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const newValue = event.currentTarget.value
    setQuery(newValue)
    setRecentSearchDebounced(newValue)
    handleNavigateToSearchPage(newValue)
  }

  const searchIcon = () =>
    !semanticEnabled ? <SearchIcon width="24px" height="24px" /> : <SearchAltIcon width="24px" height="24px" />

  useEffect(() => {
    document.body.style.overflow = recentSearchView && !isDesktop ? 'hidden' : 'auto'
  }, [recentSearchView])

  return (
    <SearchAreaContainer ref={resultsContainerRef}>
      <StyledSearchArea
        hiddenLabel
        autoComplete="off"
        autoFocus={onSearchPage}
        variant="standard"
        placeholder={t({ id: 'header.search.placeholder' })}
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        InputProps={{
          onClick: () => !recentSearchView && setRecentSearchView(true),
          startAdornment: (
            <Button
              onClick={!isDesktop && recentSearchView ? hideSearch : navigateToSearchPage}
              aria-label={!isDesktop && recentSearchView ? 'back' : 'search'}
              variant="ghost"
              className={!isDesktop && recentSearchView ? undefined : 'search-action-button'}
            >
              {!isDesktop && recentSearchView ? <ArrowBackIcon width="24px" /> : searchIcon()}
            </Button>
          ),
          endAdornment: Boolean(query?.length) && (
            <Button onClick={handleClearSearch} className="clear-search-value" variant="ghost">
              <CloseCircleIcon width="24px" />
            </Button>
          )
        }}
      />
      {recentSearchView && <RecentSearch updateTextField={setQuery} hideSearch={hideSearch} />}
    </SearchAreaContainer>
  )
}
